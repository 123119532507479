import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
import { Code } from "@uc-common/code";
import { Link } from '~/src/components/Link';
import schema from '~/src/pages/guides/ld-json-schemas/gif2video.json';
export const frontmatter = {
  title: 'How to turn GIFs into videos on the fly with a single URL operation',
  description: 'Use this guide to explore how to easily make your GIF-dense pages lighter, more user- and SEO-friendly with a URL operation.',
  header: 'How to turn GIFs into videos on the fly with a single URL operation',
  schema
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#convert-gifs-to-videos"
        }}>{`Convert GIFs to videos`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#solution-1-use-uploadcares-gif2video-operation-in-file-urls"
            }}>{`Use Uploadcare’s gif2video operation`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#solution-2-use-manual-conversion"
            }}>{`Use manual conversion`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#replace-gifs-with-videos"
        }}>{`Replace GIFs with videos`}</a></li>
    </ul>
    <p>{`GIFS can be huge: a GIF uses a lossless LZW compression format but is not efficient enough, so it results in a large file size. Additional payload adversely impacts your app’s UX, and your SEO ranking.`}</p>
    <video width="384" height="216" autoPlay loop muted webkitPlaysinline playsInline>
  <source src="https://ucarecdn.com/af0136cc-c60a-49a3-a10f-f9319f0ce7e1/gif2video/-/format/webm/road.gif" type="video/webm" />
  <source src="https://ucarecdn.com/af0136cc-c60a-49a3-a10f-f9319f0ce7e1/gif2video/-/format/mp4/road.gif" type="video/mp4" />
    </video>
    <p>{`If you have GIFs that negatively affect the website’s performance, Lighthouse in the Chrome DevTools will give you a suggestion like this one under the Opportunities heading:`}</p>
    <img src="https://ucarecdn.com/0d8ed04c-a407-42fe-9d02-c4cca1046b55/KOSr9IivnkyaFk6RJ5u1.png" alt="Lighthouse suggestion for animated GIFs" width="600" />
    <h2 {...{
      "id": "convert-gifs-to-videos"
    }}><a parentName="h2" {...{
        "href": "#convert-gifs-to-videos"
      }}>{`Convert GIFs to videos`}</a></h2>
    <p>{`Video formats such as MP4 or WEBM reduce the file size considerably while not compromising the image quality.`}</p>
    <p>{`There are a few ways you can go about performing this conversion.`}</p>
    <h3 {...{
      "id": "solution-1-use-uploadcares-gif2video-operation-in-file-urls"
    }}><a parentName="h3" {...{
        "href": "#solution-1-use-uploadcares-gif2video-operation-in-file-urls"
      }}>{`Solution 1: Use Uploadcare’s `}<code parentName="a" {...{
          "className": "lang-text"
        }}>{`gif2video`}</code>{` operation in file URLs`}</a></h3>
    <h3 {...{
      "id": "upload-your-gif-to-uploadcare"
    }}><a parentName="h3" {...{
        "href": "#upload-your-gif-to-uploadcare"
      }}>{`Upload your GIF to Uploadcare`}</a></h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Create an `}<Link to="https://app.uploadcare.com/accounts/signup" target="_blank" mdxType="Link">{`account`}</Link>{`; it’s free`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Navigate to your `}<Link to="https://app.uploadcare.com/" target="_blank" mdxType="Link">{`dashboard`}</Link>{` and choose a project where your files will go`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Upload your GIF`}</p>
      </li>
    </ul>
    <p>{`Or just use one of the File Uploader `}<Link to="https://uploadcare.com/docs/integrations/" target="_blank" mdxType="Link">{`integrations`}</Link>{`.`}</p>
    <p>{`Every uploaded file gets a unique URL that follows this structure:`}</p>
    <p><code parentName="p" {...{
        "className": "lang-text"
      }}>{`https://ucarecdn.com/:uuid/:filename`}</code></p>
    <p>{`Where:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><code parentName="p" {...{
            "className": "lang-text"
          }}>{`:uuid`}</code>{` is a unique file identifier`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><code parentName="p" {...{
            "className": "lang-text"
          }}>{`:filename`}</code>{` is the original filename`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "append-the-gif2video-operation-to-the-file-url"
    }}><a parentName="h3" {...{
        "href": "#append-the-gif2video-operation-to-the-file-url"
      }}>{`Append the `}<code parentName="a" {...{
          "className": "lang-text"
        }}>{`gif2video`}</code>{` operation to the file URL`}</a></h3>
    <p>{`Once your GIF is uploaded, get the `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`uuid`}</code>{` for your file, and then you can start using Uploadcare’s `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`gif2video`}</code>{` API endpoint to handle the conversion.`}</p>
    <p><code parentName="p" {...{
        "className": "lang-text"
      }}>{`gif2video`}</code>{` operation supports GIF, WebP, and HEIC image formats and can convert them to their video counterparts.`}</p>
    <p>{`The endpoint has the following URL structure:`}</p>
    <p><code parentName="p" {...{
        "className": "lang-text"
      }}>{`/:uuid/gif2video/-/format/{video format}/-/quality/{quality level}/`}</code></p>
    <p>{`Where:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><code parentName="p" {...{
            "className": "lang-text"
          }}>{`:uuid`}</code>{` represents the unique id in Uploadcare’s CDN URL for your GIF.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><code parentName="p" {...{
            "className": "lang-text"
          }}>{`{video format}`}</code>{` represents the required format. Currently, `}<code parentName="p" {...{
            "className": "lang-text"
          }}>{`mp4`}</code>{` and `}<code parentName="p" {...{
            "className": "lang-text"
          }}>{`webm`}</code>{` values are supported. Default is `}<code parentName="p" {...{
            "className": "lang-text"
          }}>{`mp4`}</code>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><code parentName="p" {...{
            "className": "lang-text"
          }}>{`{quality level}`}</code>{` represents the required quality. Currently, `}<code parentName="p" {...{
            "className": "lang-text"
          }}>{`lightest`}</code>{` , `}<code parentName="p" {...{
            "className": "lang-text"
          }}>{`lighter`}</code>{` , `}<code parentName="p" {...{
            "className": "lang-text"
          }}>{`normal`}</code>{`, `}<code parentName="p" {...{
            "className": "lang-text"
          }}>{`better`}</code>{` and `}<code parentName="p" {...{
            "className": "lang-text"
          }}>{`best`}</code>{` values are supported. Default is `}<code parentName="p" {...{
            "className": "lang-text"
          }}>{`normal`}</code>{`.`}</p>
      </li>
    </ul>
    <p>{`For example, if Uploadcare’s CDN URL for your GIF is `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`https://ucarecdn.com/70ccc603-a7d2-4988-8ab1-927e64d1d7ef/spongebobrainbow.gif`}</code>{`, use the following URL to get an `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`MP4`}</code>{` counterpart of your GIF with a `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`normal`}</code>{` quality level:`}</p>
    <p><code parentName="p" {...{
        "className": "lang-text"
      }}>{`https://ucarecdn.com/70ccc603-a7d2-4988-8ab1-927e64d1d7ef/gif2video/-/format/mp4/-/quality/normal/`}</code></p>
    <video width="442" height="332" autoPlay loop muted webkitPlaysinline playsInline>
  <source src="https://ucarecdn.com/70ccc603-a7d2-4988-8ab1-927e64d1d7ef/gif2video/-/format/mp4/-/quality/normal/" type="video/mp4" />
    </video>
    <p>{`And that’s it! You don’t need to worry about conversion anymore; just use this URL in your video player and let Uploadcare’s magic handle the rest. You can refer to the `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`gif2video`}</code>{` endpoint documentation `}<Link to="https://uploadcare.com/docs/transformations/gif-to-video/" target="_blank" mdxType="Link">{`here`}</Link>{`.`}</p>
    <h3 {...{
      "id": "solution-2-use-manual-conversion"
    }}><a parentName="h3" {...{
        "href": "#solution-2-use-manual-conversion"
      }}>{`Solution 2: Use manual conversion`}</a></h3>
    <p>{`You can also manually convert a GIF to different video formats by using `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`ffmpeg`}</code>{`. `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`ffmpeg`}</code>{` is a powerful tool that is widely used for video format conversion and transformation and can handle conversions from GIF to `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`webm`}</code>{`, and `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`mp4`}</code>{` as well.`}</p>
    <p>{`However, it is a manual process that you would have to repeat for every GIF. Once the GIFs are converted, you will have to upload them to Uploadcare, or a third-party CDN, and embed the final URL into your app.`}</p>
    <h2 {...{
      "id": "replace-gifs-with-videos"
    }}><a parentName="h2" {...{
        "href": "#replace-gifs-with-videos"
      }}>{`Replace GIFs with videos`}</a></h2>
    <p>{`Now that you know how to convert a GIF to different video formats, you can replace your GIFs with videos.`}</p>
    <p>{`Start by finding out all the `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`<img>`}</code>{` tags that use a GIF as their source:`}</p>
    <Code code={`<img src="{URL_TO_GIF}" />`} language='html' mdxType="Code" />
    <p>{`Replace them with HTML5 `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`<video>`}</code>{` tags that contain Uploadcare’s `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`gif2video`}</code>{` URL as the source:`}</p>
    <Code language='html' code={`
<video>
  <source src="https://ucarecdn.com/:uuid/gif2video/-/format/webm/-/quality/normal/"
  type="video/webm">
  <source src="https://ucarecdn.com/:uuid/gif2video/-/format/mp4/-/quality/normal/"
  type="video/mp4">
</video>
  `} beautifyOptions={{
      wrap_attributes: 'auto'
    }} mdxType="Code" />
    <p>{`We recommend you define an HTML5 `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`<video>`}</code>{` tag with 2 `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`sources`}</code>{`: the first source will be used if the user’s browser supports `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`webm`}</code>{`, and will play a `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`webm`}</code>{` video counterpart of your GIF. The second source will be used as a fallback that plays an `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`mp4`}</code>{` video.`}</p>
    <p>{`Note that the 3 key traits of a GIF (they play automatically, loop continuously, and they’re silent) can be replicated by using video properties. Thus, you can replace all your `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`<img>`}</code>{` tags containing GIFs with the following:`}</p>
    <Code code={`
<video autoplay loop muted playsinline>
  <source src="https://ucarecdn.com/:uuid/gif2video/-/format/webm/-/quality/normal/"
  type="video/webm">
  <source src="https://ucarecdn.com/:uuid/gif2video/-/format/mp4/-/quality/normal/"
  type="video/mp4">
</video>
  `} beautifyOptions={{
      wrap_attributes: 'auto'
    }} language='html' mdxType="Code">
    </Code>
    <p>{`If you have any questions, be sure to reach out to our helpful team of Support engineers at `}<Link to="mailto:help@uploadcare.com" target="_blank" mdxType="Link"><a parentName="p" {...{
          "href": "mailto:help@uploadcare.com"
        }}>{`help@uploadcare.com`}</a></Link>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      